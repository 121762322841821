/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import {
  Box,
  FormControl,
  IconButton,
  Skeleton,
  Typography,
  useMediaQuery,
} from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import dayjs from 'dayjs';
import 'dayjs/locale/ja'; // Import the Japanese locale
import 'dayjs/locale/fr'; // Import the French locale
import 'dayjs/locale/de'; // Import the German locale
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ScriptableContext,
  ArcElement,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Line, Doughnut } from 'react-chartjs-2';
import CountUp from 'react-countup';

import {
  // PAGE_PATH_NAME,
  FEATURE_PERMISSION,
  regexJapanese,
} from '../../constants/constants';
import useAccessKey from '../../hooks/useAccessKey';
// import useCheckTransition from '../../hooks/useCheckTransition';
import useAccountInfo from '../../hooks/useAccountInfo';
import { useStyles } from '../Templates/CommonStyle';
import { ReactComponent as DropDownIcon } from '../../images/icon/avatar-arrow.svg';
import { ReactComponent as TotalDeviceIcon } from '../../images/icon/total-device.svg';
import { ReactComponent as TotalTranslationsIcon } from '../../images/icon/total-translations.svg';
import { ReactComponent as AverageTranslationsIcon } from '../../images/icon/average-translation.svg';
import { ReactComponent as DataProtectionIcon } from '../../images/icon/data-protection.svg';
import { ReactComponent as TickIcon } from '../../images/icon/tick-dashboard.svg';
import { ReactComponent as PartialIcon } from '../../images/icon/partial.svg';
import { ReactComponent as NotOkIcon } from '../../images/icon/not-ok.svg';
import { ReactComponent as FeatureLimitIcon } from '../../images/icon/feature-limit.svg';
import { ReactComponent as WifiSettingIcon } from '../../images/icon/wi-fi-setting.svg';
// import { ReactComponent as SaveHistoryIcon } from '../../images/icon/save-history.svg';

import { ReactComponent as LostDeviceGreenIcon } from '../../images/icon/lost-device-green.svg';
import { ReactComponent as LostDeviceOrangeIcon } from '../../images/icon/lost-device-orange.svg';

import { ReactComponent as SimOrangeIcon } from '../../images/icon/sim-orange.svg';
import { ReactComponent as SimGreenIcon } from '../../images/icon/sim-green.svg';

import { ReactComponent as NoDataIcon } from '../../images/icon/no-data-icon.svg';

import PopperCustom from '../Atoms/popperCustom';
// import AgreementUpdate from '../Dialog/AgreementUpdate';
import {
  translationsOptions,
  translationsOptionsForAreaChart,
  translationsOptionsObject,
} from '../../utils/dashboardConstants';
import WelcomeDialog from '../Dialog/WelcomeDialog';
import {
  dateRangeCalculator,
  dateLabelList,
  getDayName,
} from '../../utils/dashboard/dateRangeCalculator';
import useDashboard from '../../hooks/useDashboard';
import Loader from '../Atoms/Loader';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import { sendGAEvent } from '../../hooks/useGAEvent';
import translationOptionsLocale from '../../utils/dashboard/translationOptionsLocale';
import { LOCALE_CODE } from '../../hooks/useLocale';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ArcElement,
  ChartDataLabels,
);

const Dashboard = (): JSX.Element => {
  const isTablet = useMediaQuery('(max-width:1250px)');
  const { innerWidth: width } = window;
  const areaChartHeight = width > 500 ? 65 : 150;

  const [spacesForAlign, setSpacesForAlign] = useState(isTablet ? '' : '   ');
  const [fontSize, setFontSize] = useState(isTablet ? 9 : 11);

  const [currentDateRange, setCurrentDateRange] = useState<{
    firstDay: string;
    lastDay: string;
  }>(dateRangeCalculator('This Month'));

  useEffect(() => {
    // Update spacesForAlign and font size when window width changes
    setSpacesForAlign(isTablet ? '' : '   ');
    setFontSize(isTablet ? 9 : 11);
  }, [isTablet]);

  const { accessKey } = useAccessKey();
  const { accountInfo } = useAccountInfo();
  // const { allowTransition } = useCheckTransition();
  const { t } = useSwitchLocaleLanguage();
  const classes = useStyles();

  let labelsMonth = [
    `   ${t('dashboard.labelsMonthShortName.jan')}`,
    t('dashboard.labelsMonthShortName.feb'),
    t('dashboard.labelsMonthShortName.mar'),
    t('dashboard.labelsMonthShortName.apr'),
    t('dashboard.labelsMonthShortName.may'),
    t('dashboard.labelsMonthShortName.jun'),
    t('dashboard.labelsMonthShortName.jul'),
    t('dashboard.labelsMonthShortName.aug'),
    t('dashboard.labelsMonthShortName.sep'),
    t('dashboard.labelsMonthShortName.oct'),
    t('dashboard.labelsMonthShortName.nov'),
    `${t('dashboard.labelsMonthShortName.dec')}  `,
  ];

  let labelsMonthListTooltip = [
    t('dashboard.labelsMonthShortName.jan'),
    t('dashboard.labelsMonthShortName.feb'),
    t('dashboard.labelsMonthShortName.mar'),
    t('dashboard.labelsMonthShortName.apr'),
    t('dashboard.labelsMonthShortName.may'),
    t('dashboard.labelsMonthShortName.jun'),
    t('dashboard.labelsMonthShortName.jul'),
    t('dashboard.labelsMonthShortName.aug'),
    t('dashboard.labelsMonthShortName.sep'),
    t('dashboard.labelsMonthShortName.oct'),
    t('dashboard.labelsMonthShortName.nov'),
    t('dashboard.labelsMonthShortName.dec'),
  ];
  let weekDaysShortName = [
    t('dashboard.weekDaysShortName.sun'),
    t('dashboard.weekDaysShortName.mon'),
    t('dashboard.weekDaysShortName.tue'),
    t('dashboard.weekDaysShortName.wed'),
    t('dashboard.weekDaysShortName.thu'),
    t('dashboard.weekDaysShortName.fri'),
    t('dashboard.weekDaysShortName.sat'),
  ];

  let labelsMonthFullName = [
    t('dashboard.labelsMonthFullName.jan'),
    t('dashboard.labelsMonthFullName.feb'),
    t('dashboard.labelsMonthFullName.march'),
    t('dashboard.labelsMonthFullName.april'),
    t('dashboard.labelsMonthFullName.may'),
    t('dashboard.labelsMonthFullName.june'),
    t('dashboard.labelsMonthFullName.july'),
    t('dashboard.labelsMonthFullName.aug'),
    t('dashboard.labelsMonthFullName.sep'),
    t('dashboard.labelsMonthFullName.oct'),
    t('dashboard.labelsMonthFullName.nov'),
    t('dashboard.labelsMonthFullName.dec'),
  ];

  useEffect(() => {
    labelsMonth = [
      `   ${t('dashboard.labelsMonthShortName.jan')}`,
      t('dashboard.labelsMonthShortName.feb'),
      t('dashboard.labelsMonthShortName.mar'),
      t('dashboard.labelsMonthShortName.apr'),
      t('dashboard.labelsMonthShortName.may'),
      t('dashboard.labelsMonthShortName.jun'),
      t('dashboard.labelsMonthShortName.jul'),
      t('dashboard.labelsMonthShortName.aug'),
      t('dashboard.labelsMonthShortName.sep'),
      t('dashboard.labelsMonthShortName.oct'),
      t('dashboard.labelsMonthShortName.nov'),
      `${t('dashboard.labelsMonthShortName.dec')}  `,
    ];

    labelsMonthListTooltip = [
      t('dashboard.labelsMonthShortName.jan'),
      t('dashboard.labelsMonthShortName.feb'),
      t('dashboard.labelsMonthShortName.mar'),
      t('dashboard.labelsMonthShortName.apr'),
      t('dashboard.labelsMonthShortName.may'),
      t('dashboard.labelsMonthShortName.jun'),
      t('dashboard.labelsMonthShortName.jul'),
      t('dashboard.labelsMonthShortName.aug'),
      t('dashboard.labelsMonthShortName.sep'),
      t('dashboard.labelsMonthShortName.oct'),
      t('dashboard.labelsMonthShortName.nov'),
      t('dashboard.labelsMonthShortName.dec'),
    ];

    weekDaysShortName = [
      t('dashboard.weekDaysShortName.sun'),
      t('dashboard.weekDaysShortName.mon'),
      t('dashboard.weekDaysShortName.tue'),
      t('dashboard.weekDaysShortName.wed'),
      t('dashboard.weekDaysShortName.thu'),
      t('dashboard.weekDaysShortName.fri'),
      t('dashboard.weekDaysShortName.sat'),
    ];

    labelsMonthFullName = [
      t('dashboard.labelsMonthFullName.jan'),
      t('dashboard.labelsMonthFullName.feb'),
      t('dashboard.labelsMonthFullName.march'),
      t('dashboard.labelsMonthFullName.april'),
      t('dashboard.labelsMonthFullName.may'),
      t('dashboard.labelsMonthFullName.june'),
      t('dashboard.labelsMonthFullName.july'),
      t('dashboard.labelsMonthFullName.aug'),
      t('dashboard.labelsMonthFullName.sep'),
      t('dashboard.labelsMonthFullName.oct'),
      t('dashboard.labelsMonthFullName.nov'),
      t('dashboard.labelsMonthFullName.dec'),
    ];
  }, [accountInfo.locale, t]);

  const {
    deviceValue,
    remoteSettingValue,
    callTotalTranslationApi,
    totalTranslations,
    isCallingDeviceApi,
    callAverageTranslationApi,
    averageTranslations,
    isCallingTotalTranslationsApi,
    isCallingAverageTranslationsApi,
    numberOfTranslationsData,
    callNumberOfTranslationsApi,
    callLanguageFromTranslationApi,
    languageFromTranslationData,
    isCallingLanguageFromTranslationApi,
    callLanguageToTranslationApi,
    languageToTranslationData,
    isCallingLanguageToTranslationApi,
    isCallingNumberOfTranslationsApi,
    isCallingRemoteApi,
    translationFromTimeSelected,
    setTranslationFromTimeSelected,
    translationToTimeSelected,
    setTranslationToTimeSelected,
  } = useDashboard();
  // const navigate = useNavigate();
  /* eslint-disable */
  useEffect(() => {
    // !accessKey && navigate(PAGE_PATH_NAME.LOGON);
    // if (!allowTransition()) {
    //   window.location.reload();
    // }
    if (accessKey && !accountInfo.userId) {
      window.location.reload();
    }
  }, [accessKey]);
  /* eslint-enable */
  const ViewSwitcherPermission =
    accountInfo.featurePermissions.DASHBOARDMANAGEMENT?.DASHBOARDPERIODSWITCHING?.includes(
      FEATURE_PERMISSION.DASHBOARDMANAGEMENT.DASHBOARDPERIODSWITCHING.VIEWLIST,
    );
  // start total translation popper
  const [rowEl, setRowEl] = useState<null | HTMLElement>(null);

  const rowSelectMenuOpen = Boolean(rowEl);
  const [totalTranslationTimeSelected, setTotalTranslationTimeSelected] =
    useState<string>('This Month');

  const handleRowMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setRowEl(event.currentTarget);
  };

  const handleRowMenuClose = () => {
    setRowEl(null);
  };

  const rowSelectId = rowSelectMenuOpen ? 'simple-popper' : undefined;
  const popperOnclickHandler = (val: string) => {
    const dateRange = dateRangeCalculator(val);
    callTotalTranslationApi(dateRange);
    setTotalTranslationTimeSelected(val);
    setRowEl(null);

    // GA tag
    sendGAEvent('select_list', 'txt_name', 'select_sum_cnt_list');
    sendGAEvent('select_list', 'select_sum_cnt_list_value', val);
  };

  // end total translation popper

  // start average translation popper
  const [rowElAverage, setRowElAverage] = useState<null | HTMLElement>(null);

  const rowSelectMenuOpenAverage = Boolean(rowElAverage);
  const [averageTranslationTimeSelected, setAverageTranslationTimeSelected] =
    useState<string>('This Month');

  const handleRowMenuOpenAverage = (event: React.MouseEvent<HTMLElement>) => {
    setRowElAverage(event.currentTarget);
  };

  const handleRowMenuCloseAverage = () => {
    setRowElAverage(null);
  };

  const rowSelectIdAverage = rowSelectMenuOpenAverage
    ? 'simple-popper'
    : undefined;
  const popperOnclickHandlerAverage = (val: string) => {
    const dateRange = dateRangeCalculator(val);
    callAverageTranslationApi(dateRange);
    setAverageTranslationTimeSelected(val);
    setRowElAverage(null);

    // GA tag
    sendGAEvent('select_list', 'txt_name', 'select_avg_cnt_list');
    sendGAEvent('select_list', 'select_avg_cnt_list_value', val);
  };

  // end average translation popper

  // start area chart time selector popper
  const [rowElAreaChart, setRowElAreaChart] = useState<null | HTMLElement>(
    null,
  );

  const rowSelectMenuOpenAreaChart = Boolean(rowElAreaChart);
  const [areaChartTimeSelected, setAreaChartTimeSelected] =
    useState<string>('This Month');

  const handleRowMenuOpenAreaChart = (event: React.MouseEvent<HTMLElement>) => {
    setRowElAreaChart(event.currentTarget);
  };

  const handleRowMenuCloseAreaChart = () => {
    setRowElAreaChart(null);
  };

  const rowSelectIdAreaChart = rowSelectMenuOpenAreaChart
    ? 'simple-popper'
    : undefined;
  const popperOnclickHandlerAreaChart = (val: string) => {
    const dateRange = dateRangeCalculator(val);
    const yearFlag =
      val === translationsOptionsObject.PREVIOUS_YEAR ||
      val === translationsOptionsObject.THIS_YEAR;

    setCurrentDateRange(dateRange);

    callNumberOfTranslationsApi(dateRange, yearFlag);
    setAreaChartTimeSelected(val);
    setRowElAreaChart(null);

    // GA tag
    sendGAEvent('select_list', 'txt_name', 'select_cnt_list');
    sendGAEvent('select_list', 'select_cnt_list_value', val);
  };

  // end area chart time selector popper

  // start translation from time selector popper
  const [rowElTranslationFrom, setRowElTranslationFrom] =
    useState<null | HTMLElement>(null);

  const rowSelectMenuOpenTranslationFrom = Boolean(rowElTranslationFrom);

  const handleRowMenuOpenTranslationFrom = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    setRowElTranslationFrom(event.currentTarget);
  };

  const handleRowMenuCloseTranslationFrom = () => {
    setRowElTranslationFrom(null);
  };

  const rowSelectIdTranslationFrom = rowSelectMenuOpenTranslationFrom
    ? 'simple-popper'
    : undefined;
  const popperOnclickHandlerTranslationFrom = (val: string) => {
    const dateRange = dateRangeCalculator(val);
    callLanguageFromTranslationApi(dateRange);
    setTranslationFromTimeSelected(val);
    setRowElTranslationFrom(null);

    // GA tag
    sendGAEvent('select_list', 'txt_name', 'select_trans_from_list');
    sendGAEvent('select_list', 'select_trans_from_list_value', val);
  };

  // end translation from time selector popper

  // start translation to time selector popper
  const [rowElTranslationTo, setRowElTranslationTo] =
    useState<null | HTMLElement>(null);

  const rowSelectMenuOpenTranslationTo = Boolean(rowElTranslationTo);

  const handleRowMenuOpenTranslationTo = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    setRowElTranslationTo(event.currentTarget);
  };

  const handleRowMenuCloseTranslationTo = () => {
    setRowElTranslationTo(null);
  };

  const rowSelectIdTranslationTo = rowSelectMenuOpenTranslationTo
    ? 'simple-popper'
    : undefined;
  const popperOnclickHandlerTranslationTo = (val: string) => {
    const dateRange = dateRangeCalculator(val);
    callLanguageToTranslationApi(dateRange);
    setTranslationToTimeSelected(val);
    setRowElTranslationTo(null);

    // GA tag
    sendGAEvent('select_list', 'txt_name', 'select_trans_to_list');
    sendGAEvent('select_list', 'select_trans_to_list_value', val);
  };

  // end translation to time selector popper

  // area chart
  const [areaChartData, setAreaChartData] = useState<number[]>([]);
  const [areaChartLabel, setAreaChartLabel] = useState<string[]>([]);
  const [areaChartToolTip, setAreaChartToolTip] = useState<string[]>([]);
  const [areaChartMaxValue, setAreaChartMaxValue] = useState<number>(1200);

  const today = new Date();
  const formattedDate = today?.toLocaleDateString(accountInfo?.locale, {
    year: 'numeric',
    month: 'long',
  });
  const [areaChartFooterLabel, setAreaChartFooterLabel] =
    useState<string>(formattedDate);

  //   const defaultMonthIndex = today.getMonth();
  // const defaultYear = today.getFullYear();
  // const defaultMonth: string = labelsMonthFullName[defaultMonthIndex];
  // const [areaChartFooterLabel, setAreaChartFooterLabel] = useState<string>(
  //   `${defaultMonth} ${defaultYear}`,
  // );

  useEffect(() => {
    if (!isCallingNumberOfTranslationsApi) {
      try {
        if (
          areaChartTimeSelected === translationsOptionsObject.PREVIOUS_YEAR ||
          areaChartTimeSelected === translationsOptionsObject.THIS_YEAR
        ) {
          const theYear =
            dayjs().year() -
            (areaChartTimeSelected === translationsOptionsObject.THIS_YEAR
              ? 0
              : 1);
          setAreaChartFooterLabel(theYear.toString());
        } else if (
          areaChartTimeSelected === translationsOptionsObject.PREVIOUS_WEEK ||
          areaChartTimeSelected === translationsOptionsObject.THIS_WEEK
        ) {
          // if the weeks overlap during the two months or between two years
          const firstDate = dateLabelList(currentDateRange)[0];
          const lastDate =
            dateLabelList(currentDateRange)[
              dateLabelList(currentDateRange).length - 1
            ];

          const firstMonthIndex = Number(firstDate.split('-')[1]) - 1;
          const lastMonthIndex = Number(lastDate.split('-')[1]) - 1;
          const firstYear = Number(firstDate.split('-')[0]);
          const lastYear = Number(lastDate.split('-')[0]);

          if (firstYear !== lastYear) {
            let footerLable;
            if (accountInfo.locale === LOCALE_CODE.JA) {
              footerLable = `${firstYear}年${labelsMonthFullName[firstMonthIndex]} - ${lastYear}年${labelsMonthFullName[lastMonthIndex]}`;
            } else {
              footerLable = `${labelsMonthFullName[firstMonthIndex]}, ${firstYear} - ${labelsMonthFullName[lastMonthIndex]}, ${lastYear}`;
            }
            setAreaChartFooterLabel(footerLable);
          } else if (firstMonthIndex !== lastMonthIndex) {
            let footerLable;
            if (accountInfo.locale === LOCALE_CODE.JA) {
              footerLable = `${labelsMonthFullName[firstMonthIndex]} - ${firstYear}年${labelsMonthFullName[lastMonthIndex]}`;
            } else {
              footerLable = `${labelsMonthFullName[firstMonthIndex]} - ${labelsMonthFullName[lastMonthIndex]}, ${firstYear}`;
            }
            setAreaChartFooterLabel(footerLable);
          } else {
            let footerLable;
            if (accountInfo.locale === LOCALE_CODE.JA) {
              footerLable = `${firstYear}年${labelsMonthFullName[firstMonthIndex]}`;
            } else {
              footerLable = `${labelsMonthFullName[firstMonthIndex]} ${firstYear}`;
            }
            setAreaChartFooterLabel(footerLable);
          }
        } else {
          const date = dateLabelList(currentDateRange)[0];
          const monthIndex = date.split('-')[1];
          const theYear = date.split('-')[0];

          const theMonth: string = labelsMonthFullName[Number(monthIndex) - 1];
          let footerLable;
          if (accountInfo.locale === LOCALE_CODE.JA) {
            footerLable = `${theYear}年${theMonth}`;
          } else {
            footerLable = `${theMonth} ${theYear}`;
          }

          setAreaChartFooterLabel(footerLable);
        }
      } catch (e) {
        //
      }
      // // -------- (end) area chart footer label

      try {
        if (numberOfTranslationsData.length === 0) {
          return;
        }

        const filteredData = numberOfTranslationsData.map((data) =>
          parseInt(data.totalTranslation, 10),
        );

        const filteredLabel = dateLabelList(currentDateRange).map((data, i) => {
          if (
            areaChartTimeSelected === translationsOptionsObject.PREVIOUS_YEAR ||
            areaChartTimeSelected === translationsOptionsObject.THIS_YEAR
          ) {
            const theMonth = labelsMonth[Number(data)];
            if (i === dateLabelList(currentDateRange).length - 1) {
              return `${theMonth}${spacesForAlign}`;
            }

            return i === 0 ? `${spacesForAlign}${theMonth}` : theMonth;
          }

          if (
            areaChartTimeSelected === translationsOptionsObject.PREVIOUS_WEEK ||
            areaChartTimeSelected === translationsOptionsObject.THIS_WEEK
          ) {
            const dayName = weekDaysShortName[getDayName(data)];
            const dayNumber = data.split('-')[2].toString();

            const returnValueString = `${dayName}, ${dayNumber}`;
            if (i === numberOfTranslationsData.length - 1) {
              return `${returnValueString}${spacesForAlign}`;
            }

            return i === 0
              ? `${spacesForAlign}${returnValueString}`
              : returnValueString;
          }

          const returnValueString = parseInt(data.split('-')[2], 10).toString();
          if (i === dateLabelList(currentDateRange).length - 1) {
            return `${returnValueString}${spacesForAlign}`;
          }

          return i === 0
            ? `${spacesForAlign}${returnValueString}`
            : returnValueString;
        });
        setAreaChartData(filteredData);
        setAreaChartLabel(filteredLabel);

        const maxValue = Math.max(
          ...numberOfTranslationsData.map((data) =>
            parseInt(data.totalTranslation, 10),
          ),
        );
        setAreaChartMaxValue(maxValue);

        // ---------(start) area chart tooltip data formatting
        const filteredTooltipLabel = numberOfTranslationsData.map((data) => {
          const dayNameTooltip = weekDaysShortName[getDayName(data.dateTime)];
          const dayNumberTooltip = data.dateTime.split('-')[2];
          const monthTooltip =
            labelsMonthListTooltip[Number(data.dateTime.split('-')[1]) - 1];
          const yearTooltip = data.dateTime.split('-')[0];

          return `${dayNameTooltip}, ${monthTooltip} ${dayNumberTooltip}, ${yearTooltip}`;
        });
        setAreaChartToolTip(filteredTooltipLabel);
      } catch (e) {
        //
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    numberOfTranslationsData,
    spacesForAlign,
    accountInfo.locale,
    currentDateRange,
    isCallingNumberOfTranslationsApi,
  ]);

  const dataForAreaChart = useMemo(() => {
    const theData = {
      labels: areaChartLabel,
      datasets: [
        {
          fill: true,
          label: '',
          // pointColor: '#fff',
          // pointHighlightFill: '#fff',

          // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          data: areaChartData,
          borderColor:
            areaChartMaxValue === 0 ? '#E5E5E5' : 'rgb(53, 162, 235)',
          borderWidth: areaChartMaxValue === 0 ? 1 : 2,
          pointBackgroundColor: '#fff',
          // pointHoverRadius: 5,
          hoverBackgroundColor: 'rgb(53, 162, 235)',
          // borderDash: [21, 21],

          backgroundColor: (context: ScriptableContext<'line'>) => {
            const { ctx } = context.chart;
            const gradient = ctx.createLinearGradient(0, 0, 0, 560);
            gradient.addColorStop(0, 'rgba(179, 223, 255, 1)');
            gradient.addColorStop(0.5, 'rgba(255, 255, 255, 0)');

            return gradient;
          },
        },
      ],
    };

    return theData;
  }, [areaChartData, areaChartLabel, areaChartMaxValue]);
  const areaChartOptions = {
    elements: {
      point: {
        radius: areaChartMaxValue === 0 ? 0 : 3,
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: fontSize,
            fontWeight: '500',
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          // stepSize: 100,
          font: {
            size: fontSize,
            color: '#313133',
          },
          padding: 8,
          callback: (value: number) =>
            value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ''),
        },
        suggestedMin: 0,
        suggestedMax: areaChartMaxValue
          ? areaChartMaxValue + (areaChartMaxValue % 100)
          : 1200,
        grid: {
          drawTicks: false,
          drawBorder: false,
        },
      },
    },
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        position: 'top' as const,
        display: false,
        labels: {
          font: {
            size: 14,
          },
        },
      },
      title: {
        display: true,
        text: '',
      },

      tooltip: {
        enabled: areaChartMaxValue !== 0,
        pointStyle: false,
        yAlign: 'bottom',
        displayColors: false,
        titleAlign: 'center',
        // titleMarginBottom: 20,
        titleFont: {
          size: 11,
          weight: 300,
        },

        bodyAlign: 'center',
        callbacks: {
          // eslint-disable-next-line consistent-return
          label: (tooltipItem: { label: string; dataIndex: number }) => {
            if (tooltipItem?.label) {
              if (
                areaChartTimeSelected ===
                  translationsOptionsObject.PREVIOUS_YEAR ||
                areaChartTimeSelected === translationsOptionsObject.THIS_YEAR
              ) {
                const theYear =
                  dayjs().year() -
                  (areaChartTimeSelected === translationsOptionsObject.THIS_YEAR
                    ? 0
                    : 1);

                return `${tooltipItem.label.trim()}, ${theYear}`;
              }

              return areaChartToolTip[tooltipItem.dataIndex];
            }
          },
          // eslint-disable-next-line consistent-return
          title: (tooltipItem: [{ formattedValue: string }]) => {
            if (tooltipItem[0].formattedValue) {
              return `    ${tooltipItem[0].formattedValue}    `;
            }
          },
        },
      },
    },
  };

  // ------------doughnut chart language from
  const [languageFromChartData, setLanguageFromChartData] = useState<number[]>(
    [],
  );
  const [languageFromChartLabel, setLanguageFromChartLabel] = useState<
    string[]
  >([]);
  const doughnutChartColorArray = [
    '#428BFF',
    '#69B6F8',
    '#B27EEF',
    '#FF9964',
    '#5BDFB4',
    '#D9DBE6',
  ];
  useEffect(() => {
    const filteredData = languageFromTranslationData.map((data) =>
      parseInt(data.totalTranslation, 10),
    );

    const filteredLabel = languageFromTranslationData.map(
      (data) => data.language,
    );

    setLanguageFromChartData(filteredData);
    setLanguageFromChartLabel(filteredLabel);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageFromTranslationData]);

  const dataForDoughnutChartLanguageFrom = {
    labels: languageFromChartLabel,

    datasets: [
      {
        data: languageFromChartData,
        backgroundColor: doughnutChartColorArray,
        borderColor: doughnutChartColorArray,
        hoverBorderWidth: '14',
        hoverBorderJoinStyle: 'miter',
      },
    ],
  };

  // -------------------doughnut chart language to
  const [languageToChartData, setLanguageToChartData] = useState<number[]>([]);
  const [languageToChartLabel, setLanguageToChartLabel] = useState<string[]>(
    [],
  );

  useEffect(() => {
    const filteredData = languageToTranslationData.map((data) =>
      parseInt(data.totalTranslation, 10),
    );

    const filteredLabel = languageToTranslationData.map(
      (data) => data.language,
    );

    setLanguageToChartData(filteredData);
    setLanguageToChartLabel(filteredLabel);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageToTranslationData]);

  const dataForDoughnutChartLanguageTo = {
    labels: languageToChartLabel,

    datasets: [
      {
        data: languageToChartData,
        backgroundColor: doughnutChartColorArray,
        borderColor: doughnutChartColorArray,
        hoverBorderWidth: '14',
        hoverBorderJoinStyle: 'miter',
      },
    ],
  };

  // -----------options for language from doughnut

  // reset style for languageFrom text and box function
  const resetLanguageFromStyle = () => {
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < languageFromChartData.length; index++) {
      const foundText = document.getElementById(
        `dounutLanugageFromText${index}`,
      );
      const foundBox = document.getElementById(`dounutLanugageFromBox${index}`);

      if (foundText && foundBox) {
        foundText.style.fontWeight = '500';
        foundBox.style.height = '10px';
        foundBox.style.width = '10px';
      }
    }
  };

  const optionsForDoughnutLanguageFrom = {
    cutout: 47,
    aspectRatio: 1,

    layout: {
      padding: 8,
    },
    onHover: (event: any, elements: string | any[]) => {
      if (elements.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        const hoveredIndex = elements[0].index;
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < languageFromChartData.length; index++) {
          if (index === hoveredIndex) {
            // eslint-disable-next-line no-continue
            continue;
          }
          const foundText = document.getElementById(
            `dounutLanugageFromText${index}`,
          );
          const foundBox = document.getElementById(
            `dounutLanugageFromBox${index}`,
          );
          if (foundText && foundBox) {
            foundText.style.fontWeight = '500';

            foundBox.style.height = '10px';
            foundBox.style.width = '10px';
          }
        }
      } else {
        resetLanguageFromStyle();
      }
    },
    plugins: {
      borderWidth: 0,
      datalabels: {
        display: (context: { active: any }) => !!context.active,
        textAlign: 'center',
        color: '#ffff',
        font: {
          size: 9,
        },
        formatter: (
          value: any,
          context: {
            chart: { data: { labels: { [x: string]: any } } };
            dataIndex: string | number;
          },
        ) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          let arcTitle: string = context.chart.data.labels[context.dataIndex];
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const arcLabel = value;
          const isJapaneseText = regexJapanese.exec(arcTitle);
          const length = isJapaneseText ? 7 : 9;
          if (arcTitle.length > length) {
            arcTitle = arcTitle.substring(0, length).concat('...');
          }
          const foundText = document.getElementById(
            `dounutLanugageFromText${context.dataIndex}`,
          );
          const foundBox = document.getElementById(
            `dounutLanugageFromBox${context.dataIndex}`,
          );
          if (foundText && foundBox) {
            foundText.style.fontWeight = '800';
            foundBox.style.height = '11px';
            foundBox.style.width = '11px';
          }

          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          // const spaceNeed = Math.ceil(
          //   // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          //   (arcTitle.toString().length - arcLabel.toString().length) / 2,
          // );
          // const spaces = ' '.repeat(spaceNeed);

          // // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/restrict-template-expressions
          // return `${arcTitle}\n${spaces}${arcLabel}`;

          return `${arcTitle}\n${arcLabel as number}`;
        },
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false, // <-- this option disables tooltips
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };

  // -----------options for language To doughnut

  // reset style for languageTo text and box function
  const resetLanguageToStyle = () => {
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < languageToChartData.length; index++) {
      const foundText = document.getElementById(`dounutLanugageToText${index}`);
      const foundBox = document.getElementById(`dounutLanugageToBox${index}`);
      if (foundText && foundBox) {
        foundText.style.fontWeight = '500';
        foundBox.style.height = '10px';
        foundBox.style.width = '10px';
      }
    }
  };

  const optionsForDoughnutLanguageTo = {
    cutout: 47,
    aspectRatio: 1,

    layout: {
      padding: 8,
    },
    onHover: (event: any, elements: string | any[]) => {
      if (elements.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        const hoveredIndex = elements[0].index;
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < languageToChartData.length; index++) {
          if (index === hoveredIndex) {
            // eslint-disable-next-line no-continue
            continue;
          }
          const foundText = document.getElementById(
            `dounutLanugageToText${index}`,
          );
          const foundBox = document.getElementById(
            `dounutLanugageToBox${index}`,
          );
          if (foundText && foundBox) {
            foundText.style.fontWeight = '500';
            foundBox.style.height = '10px';
            foundBox.style.width = '10px';
          }
        }
      } else {
        resetLanguageToStyle();
      }
    },
    plugins: {
      borderWidth: 0,
      datalabels: {
        display: (context: { active: any }) => !!context.active,
        textAlign: 'center',
        color: '#ffff',
        font: {
          size: 9,
        },
        formatter: (
          value: any,
          context: {
            chart: { data: { labels: { [x: string]: any } } };
            dataIndex: string | number;
          },
        ) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          let arcTitle: string = context.chart.data.labels[context.dataIndex];
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const arcLabel = value;
          const isJapaneseText = regexJapanese.exec(arcTitle);
          const length = isJapaneseText ? 7 : 9;
          if (arcTitle.length > length) {
            arcTitle = arcTitle.substring(0, length).concat('...');
          }
          const foundText = document.getElementById(
            `dounutLanugageToText${context.dataIndex}`,
          );
          const foundBox = document.getElementById(
            `dounutLanugageToBox${context.dataIndex}`,
          );
          if (foundText && foundBox) {
            foundText.style.fontWeight = '800';
            foundBox.style.height = '11px';
            foundBox.style.width = '11px';
          }

          // // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          // const spaceNeed = Math.ceil(
          //   // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          //   (arcTitle.toString().length - arcLabel.toString().length) / 2,
          // );
          // const spaces = ' '.repeat(spaceNeed);

          // // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/restrict-template-expressions
          // return `${arcTitle}\n${spaces}${arcLabel}`;

          return `${arcTitle}\n${arcLabel as number}`;
        },
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };

  const formatNumberUptoTrillion = (formatNumber: string) => {
    const n = parseInt(formatNumber, 10);

    if (n >= 1e9) return `${Math.trunc(n / 1e9)}B`;

    return n;
  };

  return (
    <Box
      style={{
        margin: '0',
        padding: '40px',
        paddingTop: '30px',
        paddingBottom: '30px',
        fontSize: '30px',
        backgroundColor: '#F8F8F8',
      }}
      className="res-container"
      onMouseOver={() => {
        resetLanguageFromStyle();
        resetLanguageToStyle();
      }}
    >
      {/* First Time LogIn */}
      <WelcomeDialog />
      {/* <AgreementUpdate /> */}
      {/* Dashboard-top-Three-Card-Start */}
      <Box className="stats">
        <Box className="stats-row">
          <Box className="stats-col">
            <Box
              sx={{
                backgroundColor: '#F9E6DC',
                borderRadius: '10px',
                padding: '10px',
                paddingLeft: '17px',
              }}
              className="stats-col-inner"
            >
              <Box
                sx={{ display: 'flex', justifyContent: 'space-between' }}
                className="stats-col-inner-row"
              >
                <Typography className={classes.cardTitleDashboard}>
                  {t('dashboard.label.totalDevices')}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{ fontSize: '32px', fontWeight: '700' }}>
                  {isCallingDeviceApi ? (
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{
                        width: '50px',
                        height: '38px',
                        marginTop: '10px',
                      }}
                    />
                  ) : (
                    <CountUp end={deviceValue.totalDeviceCount} duration={1} />
                  )}
                </Typography>
                <TotalDeviceIcon />
              </Box>
            </Box>
          </Box>
          <Box className="stats-col">
            <Box
              sx={{
                backgroundColor: '#E2E3F6',
                borderRadius: '10px',
                padding: '12px 13px 10px 17px',
              }}
              className="stats-col-inner"
            >
              <Box
                sx={{ display: 'flex', justifyContent: 'space-between' }}
                className="stats-col-inner-row"
              >
                <Typography
                  className={`${classes.cardTitleDashboard} stats-col-inner-col`}
                  sx={{ maxWidth: '200px' }}
                >
                  {t('dashboard.label.totalTranslations')}
                </Typography>
                <FormControl
                  sx={{ marginLeft: '-6px' }}
                  className="stats-col-inner-col stats-col-inner-col-form"
                >
                  {/* custom select start */}
                  <IconButton
                    size="large"
                    aria-label="3"
                    aria-controls={rowSelectId}
                    aria-describedby={rowSelectId}
                    color="inherit"
                    aria-haspopup="true"
                    onClick={handleRowMenuOpen}
                    className={classes.timePeriodRowBtn}
                    disabled={!ViewSwitcherPermission}
                    sx={{ backgroundColor: '#FFFFFF80 !important' }}
                  >
                    <Typography
                      className={classes.cardSelectBoxPlaceholderDashboard}
                    >
                      {translationOptionsLocale(
                        totalTranslationTimeSelected,
                        t,
                      )}
                    </Typography>
                    <DropDownIcon />
                  </IconButton>
                  <PopperCustom
                    rowSelectId={rowSelectId}
                    rowSelectMenuOpen={rowSelectMenuOpen}
                    rowEl={rowEl}
                    handleRowMenuClose={handleRowMenuClose}
                    translationsOptions={translationsOptions}
                    isSelected={totalTranslationTimeSelected}
                    popperOnclickHandler={(val) => popperOnclickHandler(val)}
                  />
                  {/* custom select end */}
                </FormControl>
              </Box>
              <Box
                sx={{ display: 'flex', justifyContent: 'space-between' }}
                className="stats-col-inner-row"
              >
                <Typography sx={{ fontSize: '32px', fontWeight: '700' }}>
                  {isCallingTotalTranslationsApi ? (
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{
                        width: '50px',
                        height: '38px',
                        marginTop: '10px',
                      }}
                    />
                  ) : (
                    <Box>
                      {parseInt(totalTranslations, 10) < 1e9 ? (
                        <CountUp
                          end={parseInt(totalTranslations, 10)}
                          duration={1}
                        />
                      ) : (
                        formatNumberUptoTrillion(totalTranslations)
                      )}
                    </Box>
                  )}
                </Typography>
                <TotalTranslationsIcon />
              </Box>
            </Box>
          </Box>
          <Box className="stats-col">
            <Box
              sx={{
                backgroundColor: '#D0F0F3',
                borderRadius: '10px',
                padding: '12px 13px 10px 17px',
              }}
              className="stats-col-inner"
            >
              <Box
                sx={{ display: 'flex', justifyContent: 'space-between' }}
                className="stats-col-inner-row"
              >
                <Typography
                  className={`${classes.cardTitleDashboard} stats-col-inner-col`}
                  sx={{ maxWidth: '200px' }}
                >
                  {t('dashboard.label.avgTranslations')}
                  <br />
                  {/* eslint-disable-next-line */}
                  <span className={classes.cardSubTitleDashboard}>
                    {t('dashboard.label.avgTranslationsSubText')}
                  </span>
                </Typography>
                <FormControl
                  sx={{ marginLeft: '-6px' }}
                  className="stats-col-inner-col stats-col-inner-col-form"
                >
                  {/* custom select start */}
                  <IconButton
                    size="large"
                    aria-label="3"
                    aria-controls={rowSelectIdAverage}
                    aria-describedby={rowSelectIdAverage}
                    color="inherit"
                    aria-haspopup="true"
                    onClick={handleRowMenuOpenAverage}
                    sx={{ backgroundColor: '#E8F8F9 !important' }}
                    className={classes.timePeriodRowBtn}
                    disabled={!ViewSwitcherPermission}
                  >
                    <Typography
                      className={classes.cardSelectBoxPlaceholderDashboard}
                    >
                      {translationOptionsLocale(
                        averageTranslationTimeSelected,
                        t,
                      )}
                    </Typography>
                    <DropDownIcon />
                  </IconButton>
                  <PopperCustom
                    rowSelectId={rowSelectIdAverage}
                    rowSelectMenuOpen={rowSelectMenuOpenAverage}
                    rowEl={rowElAverage}
                    handleRowMenuClose={handleRowMenuCloseAverage}
                    translationsOptions={translationsOptions}
                    isSelected={averageTranslationTimeSelected}
                    popperOnclickHandler={(val) =>
                      popperOnclickHandlerAverage(val)
                    }
                  />
                  {/* custom select end */}
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{ fontSize: '32px', fontWeight: '700' }}>
                  {isCallingAverageTranslationsApi ? (
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{
                        width: '50px',
                        height: '38px',
                        marginTop: '10px',
                      }}
                    />
                  ) : (
                    <CountUp
                      end={parseInt(averageTranslations, 10)}
                      duration={1}
                    />
                  )}
                </Typography>
                <AverageTranslationsIcon
                  style={{ marginTop: '10px', marginRight: '8px' }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Dashboard-top-Three-Card-End */}

      <Box
        sx={{
          maxWidth: '1200px',
          margin: '20px auto',
          backgroundColor: '#fff',
          padding: '24px 19px 13px 23px',
          borderRadius: '8px',
          border: '1px solid rgba(222, 223, 223, 0.5)',
          position: 'relative',
        }}
      >
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between' }}
          className="stats-col-inner-row"
        >
          <Typography
            sx={{ fontSize: '18px', fontWeight: '500' }}
            className="stats-col-inner-col"
          >
            {t('dashboard.label.numberTranslation')}
          </Typography>
          <FormControl
            sx={{ marginLeft: '-6px' }}
            className="stats-col-inner-col stats-col-inner-col-form"
          >
            {/* custom select start */}
            <IconButton
              size="large"
              aria-label="3"
              aria-controls={rowSelectIdAreaChart}
              aria-describedby={rowSelectIdAreaChart}
              color="inherit"
              aria-haspopup="true"
              onClick={handleRowMenuOpenAreaChart}
              sx={{
                backgroundColor: '#FBFBFB !important',
              }}
              className={classes.timePeriodRowBtn}
              disabled={!ViewSwitcherPermission}
            >
              <Typography className={classes.cardSelectBoxPlaceholderDashboard}>
                {translationOptionsLocale(areaChartTimeSelected, t)}
              </Typography>
              <DropDownIcon />
            </IconButton>

            <PopperCustom
              rowSelectId={rowSelectIdAreaChart}
              rowSelectMenuOpen={rowSelectMenuOpenAreaChart}
              rowEl={rowElAreaChart}
              handleRowMenuClose={handleRowMenuCloseAreaChart}
              translationsOptions={translationsOptionsForAreaChart}
              isSelected={areaChartTimeSelected}
              popperOnclickHandler={(val) => popperOnclickHandlerAreaChart(val)}
            />
            {/* custom select end */}
          </FormControl>
        </Box>
        {(areaChartData.length === 0 || areaChartMaxValue === 0) &&
          !isCallingNumberOfTranslationsApi && (
            <Box
              sx={{ position: 'absolute', top: '44%', left: '44%' }}
              className="res-d-none"
            >
              <Box
                sx={{
                  minWidth: '120px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <NoDataIcon />
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: '400',
                    color: '#C5C5C5',
                  }}
                >
                  {t('common.error.noData')}
                </Typography>
              </Box>
            </Box>
          )}

        {isCallingNumberOfTranslationsApi && (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '48%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Loader colorCode="#b69696" />
          </Box>
        )}

        <Line
          height={areaChartHeight}
          /* @ts-expect-error will remove soon */
          options={areaChartOptions}
          data={dataForAreaChart}
        />

        <Typography
          sx={{ fontSize: '13px', fontWeight: '500', textAlign: 'center' }}
        >
          {areaChartFooterLabel}
        </Typography>
      </Box>

      {/* doughnut chart start */}
      <Box className={classes.dashboardDoughnutChartContainer}>
        <Box className={classes.dashboardDoughnutChart}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: '24px',
              paddingTop: '18px',
              paddingRight: '20px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '5px',
              }}
              className="stats-col-inner-row"
            >
              <Typography
                sx={{ fontSize: '18px', fontWeight: '500' }}
                className="stats-col-inner-col"
              >
                {t('dashboard.label.languageUsedFrom')}
              </Typography>
              <FormControl
                sx={{ marginLeft: '-6px' }}
                className="stats-col-inner-col stats-col-inner-col-form"
              >
                {/* custom select start */}
                <IconButton
                  size="large"
                  aria-label="3"
                  aria-controls={rowSelectIdTranslationFrom}
                  aria-describedby={rowSelectIdTranslationFrom}
                  color="inherit"
                  aria-haspopup="true"
                  onClick={handleRowMenuOpenTranslationFrom}
                  sx={{
                    backgroundColor: '#FBFBFB !important',
                  }}
                  className={classes.timePeriodRowBtn}
                  disabled={!ViewSwitcherPermission}
                >
                  <Typography
                    className={classes.cardSelectBoxPlaceholderDashboard}
                  >
                    {translationOptionsLocale(translationFromTimeSelected, t)}
                  </Typography>
                  <DropDownIcon />
                </IconButton>

                <PopperCustom
                  rowSelectId={rowSelectIdTranslationFrom}
                  rowSelectMenuOpen={rowSelectMenuOpenTranslationFrom}
                  rowEl={rowElTranslationFrom}
                  handleRowMenuClose={handleRowMenuCloseTranslationFrom}
                  translationsOptions={translationsOptions}
                  isSelected={translationFromTimeSelected}
                  popperOnclickHandler={(val) =>
                    popperOnclickHandlerTranslationFrom(val)
                  }
                />
                {/* custom select end */}
              </FormControl>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '10px',
                '@media screen and (max-width: 1280px)': {
                  justifyContent: 'flex-start',
                  gap: '60px',
                },
              }}
              className="dash-chart-row"
            >
              {languageFromTranslationData.length !== 0 &&
                !isCallingLanguageFromTranslationApi && (
                  <>
                    <Box
                      className={`${classes.doughnutChartLanguageContainer} dash-chart-row-col`}
                      sx={{ marginTop: '15px' }}
                    >
                      {languageFromTranslationData.map((translation, i) => (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginLeft: '5px',
                            marginBottom: '6px',
                          }}
                        >
                          <Box sx={{ display: 'flex', gap: '16px' }}>
                            <Box
                              sx={{
                                height: '10px',
                                width: '10px',
                                borderRadius: '2px',
                                marginTop: '5px',
                                backgroundColor: doughnutChartColorArray[i],
                              }}
                              id={`dounutLanugageFromBox${i}`}
                            />
                            <Typography
                              sx={{ fontSize: '12px', marginTop: '2px' }}
                              id={`dounutLanugageFromText${i}`}
                            >
                              {translation.language}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              sx={{ fontSize: '13px', fontWeight: '700' }}
                            >
                              {translation.totalTranslation}
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                    <Box
                      sx={{
                        maxWidth: '225px',
                        minWidth: '145px',
                        padding: '15px',
                      }}
                      className="dash-chart-row-col"
                    >
                      <Doughnut
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        data={dataForDoughnutChartLanguageFrom as any}
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        options={optionsForDoughnutLanguageFrom as any}
                      />
                    </Box>
                  </>
                )}
              {languageFromTranslationData.length === 0 &&
                !isCallingLanguageFromTranslationApi && (
                  <Box
                    sx={{ margin: 'auto', height: '220px' }}
                    className="dash-lang-no-data"
                  >
                    <Box
                      sx={{
                        minWidth: '120px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop: '60px',
                      }}
                      className="dash-lang-no-data-inner"
                    >
                      <NoDataIcon />
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: '400',
                          color: '#C5C5C5',
                        }}
                      >
                        {t('common.error.noData')}
                      </Typography>
                    </Box>
                  </Box>
                )}

              {isCallingLanguageFromTranslationApi && (
                <Box
                  sx={{
                    marginTop: '92px',
                    marginBottom: '108px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Loader colorCode="#b69696" />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box className={classes.dashboardDoughnutChart}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: '24px',
              paddingTop: '18px',
              paddingRight: '20px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '5px',
              }}
              className="stats-col-inner-row"
            >
              <Typography
                sx={{ fontSize: '18px', fontWeight: '500' }}
                className="stats-col-inner-col"
              >
                {t('dashboard.label.languageUsedTo')}
              </Typography>
              <FormControl
                sx={{ marginLeft: '-6px' }}
                className="stats-col-inner-col stats-col-inner-col-form"
              >
                {/* custom select start */}
                <IconButton
                  size="large"
                  aria-label="3"
                  aria-controls={rowSelectIdTranslationTo}
                  aria-describedby={rowSelectIdTranslationTo}
                  color="inherit"
                  aria-haspopup="true"
                  onClick={handleRowMenuOpenTranslationTo}
                  sx={{
                    backgroundColor: '#FBFBFB !important',
                  }}
                  className={classes.timePeriodRowBtn}
                  disabled={!ViewSwitcherPermission}
                >
                  <Typography
                    className={classes.cardSelectBoxPlaceholderDashboard}
                  >
                    {translationOptionsLocale(translationToTimeSelected, t)}
                  </Typography>
                  <DropDownIcon />
                </IconButton>

                <PopperCustom
                  rowSelectId={rowSelectIdTranslationTo}
                  rowSelectMenuOpen={rowSelectMenuOpenTranslationTo}
                  rowEl={rowElTranslationTo}
                  handleRowMenuClose={handleRowMenuCloseTranslationTo}
                  translationsOptions={translationsOptions}
                  isSelected={translationToTimeSelected}
                  popperOnclickHandler={(val) =>
                    popperOnclickHandlerTranslationTo(val)
                  }
                />
                {/* custom select end */}
              </FormControl>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '10px',
                '@media screen and (max-width: 1280px)': {
                  justifyContent: 'flex-start',
                  gap: '60px',
                },
              }}
              className="dash-chart-row"
            >
              {languageToTranslationData.length !== 0 &&
                !isCallingLanguageToTranslationApi && (
                  <>
                    <Box
                      className={`${classes.doughnutChartLanguageContainer} dash-chart-row-col`}
                      sx={{ marginTop: '15px' }}
                    >
                      {languageToTranslationData.map((translation, i) => (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginLeft: '5px',
                            marginBottom: '6px',
                          }}
                        >
                          <Box sx={{ display: 'flex', gap: '16px' }}>
                            <Box
                              sx={{
                                height: '10px',
                                width: '10px',
                                borderRadius: '2px',
                                marginTop: '5px',
                                backgroundColor: doughnutChartColorArray[i],
                              }}
                              id={`dounutLanugageToBox${i}`}
                            />
                            <Typography
                              sx={{ fontSize: '12px', marginTop: '2px' }}
                              id={`dounutLanugageToText${i}`}
                            >
                              {translation.language}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              sx={{ fontSize: '13px', fontWeight: '700' }}
                            >
                              {translation.totalTranslation}
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                    <Box
                      sx={{
                        maxWidth: '225px',
                        minWidth: '145px',
                        padding: '15px',
                      }}
                      className="dash-chart-row-col"
                    >
                      <Doughnut
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        data={dataForDoughnutChartLanguageTo as any}
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        options={optionsForDoughnutLanguageTo as any}
                      />
                    </Box>
                  </>
                )}

              {languageToTranslationData.length === 0 &&
                !isCallingLanguageToTranslationApi && (
                  <Box
                    sx={{ margin: 'auto', height: '220px' }}
                    className="dash-lang-no-data"
                  >
                    <Box
                      sx={{
                        minWidth: '120px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop: '60px',
                      }}
                      className="dash-lang-no-data-inner"
                    >
                      <NoDataIcon />
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: '400',
                          color: '#C5C5C5',
                        }}
                      >
                        {t('common.error.noData')}
                      </Typography>
                    </Box>
                  </Box>
                )}

              {isCallingLanguageToTranslationApi && (
                <Box
                  sx={{
                    marginTop: '92px',
                    marginBottom: '108px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Loader colorCode="#b69696" />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      {/* remote settings start */}
      <Box className={classes.dashboardRemoteSettingsContainer}>
        <Box className={classes.dashboardRemoteSettings}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '10px',
              paddingLeft: '20px',
              paddingTop: '14px',
              paddingRight: '14px',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{ fontSize: '18px', fontWeight: '500' }}>
                {t('dashboard.label.remoteSettingStat')}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '10px',
              }}
              className="res-remote-row"
            >
              <Box
                sx={{ width: '25%', paddingTop: '15px', paddingBottom: '15px' }}
                className="res-remote-bar-col"
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginRight: '6px',
                    position: 'relative',
                  }}
                >
                  <DataProtectionIcon />
                  {isCallingRemoteApi ? (
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{
                        height: '20px',
                        width: '15px',
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        position: 'absolute',
                        marginTop: '-18px',
                        marginLeft: '64px',
                      }}
                    >
                      {remoteSettingValue.dataProtection ===
                        t('dashboard.remoteSettingStatus.partiallySet') ||
                      remoteSettingValue.dataProtection ===
                        t('dashboard.remoteSettingStatus.notSet') ? (
                        <PartialIcon />
                      ) : (
                        <TickIcon />
                      )}
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '5px',
                  }}
                >
                  {isCallingRemoteApi ? (
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{
                        height: '18px',
                        width: '45px',
                      }}
                    />
                  ) : (
                    <Typography
                      sx={{
                        fontSize: '12px',
                        color:
                          remoteSettingValue.dataProtection ===
                            t('dashboard.remoteSettingStatus.partiallySet') ||
                          remoteSettingValue.dataProtection ===
                            t('dashboard.remoteSettingStatus.notSet')
                            ? '#F6BA2E'
                            : '#4DC176',
                      }}
                    >
                      {remoteSettingValue.dataProtection}
                    </Typography>
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '5px',
                  }}
                >
                  <Typography sx={{ fontSize: '14px' }}>
                    {t('dashboard.label.dataProtection')}
                  </Typography>
                </Box>
              </Box>
              {/* <Box
                sx={{
                  content: '" "',
                  width: '1px',
                  height: '75px',
                  backgroundColor: '#d9d9d9',
                  marginTop: '26px',
                }}
                className="res-remote-bar"
              /> */}
              {/* <Box
                sx={{ width: '25%', paddingTop: '15px', paddingBottom: '15px' }}
                className="res-remote-bar-col"
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginRight: '6px',
                    position: 'relative',
                  }}
                >
                  <SaveHistoryIcon />
                  {isCallingRemoteApi ? (
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{
                        height: '20px',
                        width: '15px',
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        position: 'absolute',
                        marginTop: '-18px',
                        marginLeft: '64px',
                      }}
                    >
                      {remoteSettingValue.saveHistorySettings ===
                        t('dashboard.remoteSettingStatus.partiallySet') ||
                      remoteSettingValue.saveHistorySettings ===
                        t('dashboard.remoteSettingStatus.notSet') ? (
                        <PartialIcon />
                      ) : (
                        <TickIcon />
                      )}
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '5px',
                  }}
                >
                  {isCallingRemoteApi ? (
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{
                        height: '18px',
                        width: '45px',
                      }}
                    />
                  ) : (
                    <Typography
                      sx={{
                        fontSize: '12px',
                        color:
                          remoteSettingValue.saveHistorySettings ===
                            t('dashboard.remoteSettingStatus.partiallySet') ||
                          remoteSettingValue.saveHistorySettings ===
                            t('dashboard.remoteSettingStatus.notSet')
                            ? '#F6BA2E'
                            : '#4DC176',
                      }}
                    >
                      {remoteSettingValue.saveHistorySettings}
                    </Typography>
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '5px',
                    textAlign: 'center',
                  }}
                >
                  <Typography sx={{ fontSize: '14px' }}>
                    {t('dashboard.label.saveHistorySettings')}
                  </Typography>
                </Box>
              </Box> */}
              <Box
                sx={{
                  content: '" "',
                  width: '1px',
                  height: '75px',
                  backgroundColor: '#d9d9d9',
                  marginTop: '26px',
                }}
                className="res-remote-bar"
              />
              <Box
                sx={{ width: '25%', paddingTop: '15px', paddingBottom: '15px' }}
                className="res-remote-bar-col"
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginRight: '6px',
                    position: 'relative',
                  }}
                >
                  <FeatureLimitIcon />
                  {isCallingRemoteApi ? (
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{
                        height: '20px',
                        width: '15px',
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        position: 'absolute',
                        marginTop: '-18px',
                        marginLeft: '64px',
                      }}
                    >
                      {remoteSettingValue.featureLimit ===
                        t('dashboard.remoteSettingStatus.partiallySet') ||
                      remoteSettingValue.featureLimit ===
                        t('dashboard.remoteSettingStatus.notSet') ? (
                        <PartialIcon />
                      ) : (
                        <TickIcon />
                      )}
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '5px',
                  }}
                >
                  {isCallingRemoteApi ? (
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{
                        height: '18px',
                        width: '45px',
                      }}
                    />
                  ) : (
                    <Typography
                      sx={{
                        fontSize: '12px',
                        color:
                          remoteSettingValue.featureLimit ===
                            t('dashboard.remoteSettingStatus.partiallySet') ||
                          remoteSettingValue.featureLimit ===
                            t('dashboard.remoteSettingStatus.notSet')
                            ? '#F6BA2E'
                            : '#4DC176',
                      }}
                    >
                      {remoteSettingValue.featureLimit}
                    </Typography>
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '5px',
                  }}
                >
                  <Typography sx={{ fontSize: '14px' }}>
                    {t('dashboard.label.featureLimit')}
                  </Typography>
                </Box>
              </Box>
              <Box
                className={`${classes.remoteSettingDividers} res-remote-bar`}
              />
              <Box
                sx={{ width: '25%', paddingTop: '15px', paddingBottom: '15px' }}
                // className={classes.remoteSettingIconContainers}
                className="res-remote-bar-col"
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginRight: '6px',
                    position: 'relative',
                  }}
                >
                  <WifiSettingIcon />
                  {isCallingRemoteApi ? (
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{
                        height: '20px',
                        width: '15px',
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        position: 'absolute',
                        marginTop: '-18px',
                        marginLeft: '64px',
                      }}
                    >
                      {remoteSettingValue.wifiSettings ===
                        t('dashboard.remoteSettingStatus.partiallySet') ||
                      remoteSettingValue.wifiSettings ===
                        t('dashboard.remoteSettingStatus.notSet') ? (
                        <PartialIcon />
                      ) : (
                        <TickIcon />
                      )}
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '5px',
                  }}
                >
                  {isCallingRemoteApi ? (
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{
                        height: '18px',
                        width: '45px',
                      }}
                    />
                  ) : (
                    <Typography
                      sx={{
                        fontSize: '12px',
                        color:
                          remoteSettingValue.wifiSettings ===
                            t('dashboard.remoteSettingStatus.partiallySet') ||
                          remoteSettingValue.wifiSettings ===
                            t('dashboard.remoteSettingStatus.notSet')
                            ? '#F6BA2E'
                            : '#4DC176',
                      }}
                    >
                      {remoteSettingValue.wifiSettings}
                    </Typography>
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '5px',
                  }}
                >
                  <Typography sx={{ fontSize: '14px' }}>
                    {t('dashboard.label.wifiSet')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* Lost Mode Device Start */}
        <Box className={`${classes.lostDeviceAndSimExpired} res-lost-mode`}>
          <Box
            sx={{
              backgroundColor: '#fff',
              width: '50%',
              borderRadius: '8px',
              border: '1px solid rgba(222, 223, 223, 0.5)',
            }}
            className="res-lost-mode-col"
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '14px 14px 43px 20px',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{ fontSize: '18px', fontWeight: '500' }}>
                  {t('dashboard.label.lostMode')}
                </Typography>
              </Box>
              <Box>
                <Box
                  sx={{
                    height: '90px',
                    width: '90px',
                    backgroundColor:
                      deviceValue.totalLostModeDeviceCount > 0
                        ? '#FFF6E2'
                        : '#EAF8EF',
                    borderRadius: '50%',
                    position: 'relative',
                    margin: '12px auto 0px auto',
                  }}
                >
                  <Box sx={{ position: 'absolute', left: '28px', top: '16px' }}>
                    {deviceValue.totalLostModeDeviceCount > 0 ? (
                      <LostDeviceOrangeIcon />
                    ) : (
                      <LostDeviceGreenIcon />
                    )}
                  </Box>
                  <Box
                    sx={{ position: 'absolute', left: '60px', top: '-16px' }}
                  >
                    {deviceValue.totalLostModeDeviceCount > 0 ? (
                      <NotOkIcon />
                    ) : (
                      <TickIcon />
                    )}
                  </Box>
                </Box>
                <Typography
                  sx={{
                    textAlign: 'center',
                    fontSize: '14px',
                    marginTop: '7px',
                    color:
                      deviceValue.totalLostModeDeviceCount > 0
                        ? '#F6BA2E'
                        : '#4DC176',
                  }}
                >
                  {deviceValue.totalLostModeDeviceCount > 0
                    ? `${deviceValue.totalLostModeDeviceCount} ${t(
                        'dashboard.label.devices',
                      )}`
                    : t('dashboard.label.noLost')}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              backgroundColor: '#fff',
              width: '50%',
              borderRadius: '8px',
              border: '1px solid rgba(222, 223, 223, 0.5)',
            }}
            className="res-lost-mode-col"
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '14px 14px 43px 20px',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{ fontSize: '18px', fontWeight: '500' }}>
                  {t('dashboard.label.simExpired')}
                </Typography>
              </Box>
              <Box>
                <Box
                  sx={{
                    height: '90px',
                    width: '90px',
                    backgroundColor:
                      deviceValue.totalSimExpiredDeviceCount > 0
                        ? '#FFF6E2'
                        : '#EAF8EF',
                    borderRadius: '50%',
                    position: 'relative',
                    margin: '12px auto 0px auto',
                  }}
                >
                  <Box sx={{ position: 'absolute', left: '28px', top: '16px' }}>
                    {deviceValue.totalSimExpiredDeviceCount > 0 ? (
                      <SimOrangeIcon />
                    ) : (
                      <SimGreenIcon />
                    )}
                  </Box>
                  <Box
                    sx={{ position: 'absolute', left: '60px', top: '-16px' }}
                  >
                    {deviceValue.totalSimExpiredDeviceCount > 0 ? (
                      <NotOkIcon />
                    ) : (
                      <TickIcon />
                    )}
                  </Box>
                </Box>
                <Typography
                  sx={{
                    textAlign: 'center',
                    fontSize: '14px',
                    marginTop: '7px',
                    color:
                      deviceValue.totalSimExpiredDeviceCount > 0
                        ? '#F6BA2E'
                        : '#4DC176',
                  }}
                >
                  {deviceValue.totalSimExpiredDeviceCount > 0
                    ? `${deviceValue.totalSimExpiredDeviceCount} ${t(
                        'dashboard.label.devices',
                      )}`
                    : t('dashboard.label.noDevice')}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
